<template>
  <div>
    <section class="section is-main-section">
      <div class="table-filter box">
        <b-field grouped>
          <b-field label="Site" v-if="!$store.state.user.siteId">
            <b-select expanded size="is-small" v-model="form.siteId">
              <option :value="null">-</option>
              <option :value="item.uuid" v-for="item in sites">{{ item.name }}</option>
            </b-select>
          </b-field>
          <b-field label="Banka">
            <b-select expanded size="is-small" v-model="form.bankId">
              <option :value="null">-</option>
              <option :value="item.uuid" v-for="item in banks">{{ item.name }}</option>
            </b-select>
          </b-field>
          <b-field label="Tarih">
            <finance-datepicker :lang="datepicker.datePickerLang" v-mask="'##.##.####-##.##.####'" v-model="form.dates"
                                type="date" range-separator="-" range format="DD.MM.YYYY"
                                :shortcuts="datepicker.shortcuts"/>
          </b-field>
          <b-field class="is-flex is-align-items-end is-flex-grow-1 is-justify-content-end">
            <b-button size="is-small" icon-left="magnify" type="is-info" @click="getReport">Filtrele</b-button>
          </b-field>
        </b-field>
      </div>
      <card-component title="Oyuncu Raporu - En çok yatırım yapan 100 oyuncuyu listeler">
        <b-table ref="cageTable"
                 :loading="$store.state.loading && $store.state.loading.length > 0"
                 :striped="true"
                 :responsive="true"
                 :hoverable="true"
                 :data="datas">
          <b-table-column label="User Id" field="userId" sortable v-slot="props">
            {{ props.row.userId }}
          </b-table-column>
          <b-table-column label="Name" field="name" sortable v-slot="props">
            {{ props.row.name }}
          </b-table-column>
          <b-table-column label="Toplam Yatırım Tutarı" field="total" sortable v-slot="props">
            ₺{{ numberFormat(props.row.total) }}
          </b-table-column>
          <b-table-column label="Toplam Yatırım Adedi" field="total" sortable v-slot="props">
            {{ props.row.count }}
          </b-table-column>
          <b-table-column label="Ortalama Yatırım" field="avg" sortable v-slot="props">
            ₺{{ numberFormat(props.row.avg) }}
          </b-table-column>
          <b-table-column label="Actions" field="actions" sortable v-slot="props">
            <b-button size="is-small" type="is-dark" class="mr-2"
                      @click="playerDetail({siteId: form.siteId, bankId: form.bankId, userId: props.row.userId, name: props.row.name})">Son 100 Yatırım
            </b-button>
            <b-button size="is-small" type="is-success" @click="goPlayer(props.row.userId, props.row.username)">Detay</b-button>
          </b-table-column>
          <section slot="empty" class="section">
            <div class="content has-text-grey has-text-centered">
              <template v-if="$store.state.loading &&  $store.state.loading.length > 0">
                <p>
                  <b-icon icon="dots-horizontal" size="is-large"/>
                </p>
                <p>Fetching data...</p>
              </template>
              <template v-else>
                <p>
                  <b-icon icon="emoticon-sad" size="is-large"/>
                </p>
                <p>Nothing's here&hellip;</p>
              </template>
            </div>
          </section>
        </b-table>
      </card-component>
    </section>
  </div>
</template>

<script>
import CardComponent from '@/components/CardComponent'
import {BankService, CustomerService, ReportService as Service, SiteService} from '@/services'
import PlayerDetail from "@/views/Report/PlayerDetail.vue"

export default {
  name: 'PlayerReport',
  components: {
    CardComponent
  },
  data() {
    return {
      datas: [],
      sites: [],
      banks: [],
      form: {
        siteId: null,
        bankId: null,
        dates: [new Date(), new Date()]
      },
      detailItems: {}
    }
  },
  mounted() {
    if (!this.$store.state.user.siteId) this.getSites()
    this.getBanks()
  },
  methods: {
    playerDetail(val){
      this.openModal({
        component: PlayerDetail,
        styles: { width: 768, height: 640},
        props: {...val},
        close: () => this.getReport
      })
    },
    getReport() {
      const startDate = this.getIstanbulDate(this.form.dates[0])
      const endDate = this.getIstanbulDate(this.form.dates[1], false)
      Service.player({
        ...this.form,
        dates: [startDate, endDate]
      }).then(res => {
        this.datas = res.data
      })
          .catch(err => this.$buefy.toast.open({message: err.message, position: 'is-top-right', type: 'is-danger'}))
    },
    getBanks() {
      BankService.all({status: 1}).then(res => this.banks = res.data)
    },
    getSites() {
      SiteService.all({status: 1}).then(res => this.sites = res.data)
    },
    goPlayer(id, name){
      CustomerService.list({siteId: this.form.siteId, userId: id, name})
          .then(res => {
            if (res.data.docs.length > 0) {
              this.$router.push({name: 'investorDetail', params: {id: res.data.docs[0].uuid}})
            }
          })
    }
  }
}
</script>
