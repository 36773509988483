<template>
  <div>
    <section class="section is-main-section p-0">
      <card-component :title="`${name} adlı oyuncunun son 100 yatırımını görüntülemektesiniz`" style="max-height: 600px; overflow-y: auto">
        <b-table ref="cageTable"
                 :loading="$store.state.loading && $store.state.loading.length > 0"
                 :striped="true"
                 :responsive="true"
                 :hoverable="true"
                 :data="deposits">
          <b-table-column label="Yatırım" field="total" sortable v-slot="props">
            ₺{{ numberFormat(props.row.total) }}
          </b-table-column>
          <b-table-column label="Tarih" field="createdAt" sortable v-slot="props">
            {{ dateFormat(props.row.createdAt, dateFormats.normalDateTimeS) }}
          </b-table-column>
          <section slot="empty" class="section">
            <div class="content has-text-grey has-text-centered">
              <template v-if="$store.state.loading &&  $store.state.loading.length > 0">
                <p>
                  <b-icon icon="dots-horizontal" size="is-large"/>
                </p>
                <p>Fetching data...</p>
              </template>
              <template v-else>
                <p>
                  <b-icon icon="emoticon-sad" size="is-large"/>
                </p>
                <p>Nothing's here&hellip;</p>
              </template>
            </div>
          </section>
        </b-table>
      </card-component>
    </section>
  </div>
</template>

<script>
import CardComponent from '@/components/CardComponent'
import {ReportService as Service} from '@/services'

export default {
  name: 'PlayeDetailReport',
  components: {
    CardComponent
  },
  props: ['userId', 'siteId', 'name', 'bankId'],
  data() {
    return {
      deposits: []
    }
  },
  mounted() {
    this.getReport()
  },
  methods: {
    getReport() {
      Service.playerDetail({siteId: this.siteId, userId: this.userId, bankId: this.bankId})
          .then(res => this.deposits = res.data)
          .catch(err => this.$buefy.toast.open({message: err.message, position: 'is-top-right', type: 'is-danger'}))
    }
  }
}
</script>
